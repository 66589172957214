<template>
  <v-container fluid>
    <section-header
      :title="$t('settingsPage.organization')"
      :has-action="false"
    />

    <v-card
      class="py-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="3"
        >
          <update-organization-form
            is-self
            :data="currentAccount"
            @update="updateOrganization"
            @delete="deleteOrganization"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import * as _ from 'lodash'
import { mapGetters, mapActions } from 'vuex';

import makeOrgService from '@/services/api/org';

import SectionHeader from '@/components/Form/SectionHeader.vue';
import UpdateOrganizationForm from '@/components/Settings/Organization/CreateUpdateOrganization.vue';

import { showSuccessToast, showErrorToast } from '@/utils/toast';

export default {
  name: 'UpdateOrganization',

  components: {
    SectionHeader,
    UpdateOrganizationForm,
  },

  data () {
    return {
      orgService: null,
    }
  },

  computed: {
    ...mapGetters({ currentAccount: 'user/currentAccount', orgs: 'user/orgs' }),
  },

  mounted() {
    this.orgService = makeOrgService(this.$api)
  },

  methods: {
    ...mapActions({
      setCurrentAccount: 'user/setCurrentAccount',
      setOrgs: 'user/setOrgs',
      setLoading: 'setLoading',
    }),

    async updateOrganization(item) {
      this.setLoading({
        loading: true,
        loadingText: this.$t('organization.updatingOrganization')
      })

      try {
        const data = {
          orgName: item.name,
          handle: item.handle,
        }

        if (item.avatarUrl) {
          data.avatarURL = item.avatarUrl
        }

        await this.orgService.updateOrg(data)

        const updatedOrgs = this.orgs.map((org) => {
          if (org.uid !== this.currentAccount.uid) {
            return org
          }

          org.name = item.name
          org.avatarUrl = item.avatarUrl

          return org
        })
        this.setOrgs(updatedOrgs)

        showSuccessToast(this.$swal, this.$t('organization.organizationUpdated'))

        this.setCurrentAccount({
          ...this.currentAccount,
          name: item.name,
          avatarUrl: item.avatarUrl,
        })
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.error || err.response?.data[0]?.msg)
      } finally {
        this.setLoading({
          loading: false,
        })
      }
    },

    async deleteOrganization(password) {
      this.setLoading({
        loading: true,
        loadingText: this.$t('organization.deletingOrganization')
      })

      try {
        await this.orgService.deleteOrg(this.currentAccount.handle, {
          password,
        })
        const filterdOrgs = this.orgs.filter(item => item.uid !== this.currentAccount.uid)

        this.setOrgs(filterdOrgs || [])

        showSuccessToast(this.$swal, this.$t('organization.organizationDeleted'))

        // After org is deleted, we redirect the page to personal settings
        this.$router.push('/settings');
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.error || err.response.data[0]?.msg)
      } finally {
        this.setLoading({
          loading: false,
        })
      }
    }
  }
}
</script>